
.background{
    background: 
    linear-gradient(40deg, #3b537a 0%, #5072A7 15%, transparent 15.1%),
    linear-gradient(130deg, #3b537a 0%, #5072A7 5%, transparent 5.1%),
    linear-gradient(-40deg, #3b537a 0%, #5072A7 15%, transparent 15.1%),
    linear-gradient(-130deg, #3b537a 0%, #5072A7 5%, transparent 5.1%),
    rgb(234, 235, 237);
    ;
    /* background: linear-gradient(40deg, #3b537a 0%, #5072A7 15%, transparent 15.1%),linear-gradient(130deg, #3b537a 0%, #5072A7 15%, transparent 15.1%),
    linear-gradient(-40deg, #3b537a 0%, #5072A7 15%, transparent 15.1%),linear-gradient(-130deg, #3b537a 0%, #5072A7 15%, transparent 15.1%);
; */
}
.row-card-one, .row-card-two{
    padding:0;
    margin:0;
    border: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px #002147; /* #002147 */
    border-radius: 10px 10px 0px 0px;

}

.row-card-one{
    background-color: #333B;

}
.row-card-one:hover{
    cursor: pointer;
    box-shadow: #0c396da8 -5px 20px 10px 5px; /* Broncos Navy*/
    transition: box-shadow 1s ease-in-out;
}
.row-card-two:hover{
    cursor: pointer;
    box-shadow: #0c396da8 5px 20px 10px 5px; /* Broncos Navy*/
    transition: box-shadow 1s ease-in-out;
}
.row-card-two {
    z-index: 0;
}

@media screen and (max-width: 768px) {
    .row-card-two {
        position: static;
        width: 100%;

    }
    
}




.row-card-two .title, .row-card-one .title{
    background: linear-gradient(10deg, #0a2756 30%, #0e62e9 100%);
    border-bottom: rgb(4, 0, 61) 3px solid;


}

/* -------------------------- INPUT FELDER START -------------------------- */
.custom-input {
    --placeholder-color: white;
    --color: white;
    --background: black;
    --border: 2px solid #002147;
  }


/* Input */
.input-design {
    width: 100%;
    border: none; /* Kein Rahmen um die Fläche */
    border-bottom: 4px solid rgba(109, 122, 241, 0.658); /* Unterstreichungseffekt */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 19px; /* Schriftgröße */
    color: #333; /* Schriftfarbe der EINGEGEBENEN Zeichen */
    transition: border-bottom-color 0.3s; /* Animiere die Farbe des Unterstreichungseffekts */
    text-align: left;
    padding: 5px;
}
.input-design::placeholder {
    color: #666; /* Schriftfarbe der placeholder */
}
ion-input{
    color:white;
}
/* Hover-Effekt für Input-Felder */
.input-design:hover {
    border-bottom: 4px solid rgb(51, 71, 253); /* Unterstreichungseffekt */

}

/* Focus-Effekt für Input-Felder */
.input-design:focus + .col-input-form{
    outline: none; /* Entfernt die Standard-Fokus-Rahmen */
    border-bottom-color: #007bff; /* Ändert die Farbe des Unterstreichungseffekts beim Fokussieren */
    color:black;
}
.input-title{
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
}


.col-input-form{
    display: flex;
    align-items: center;
    margin:0;
    padding:0;
    height: 100%;
}
/* -------------------------- INPUT FELDER ENDE -------------------------- */
.image{
    display: flex;
    justify-content: center;
    width: 100%;

    border-radius: 10px;
    box-shadow: 0 0 10px #002147; /* #002147 */
    width: 90%;
}

.grid-title{
    background-image: url("../../SVG/ColorHands/HelplycolorHands.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: clamp(255px, 30vh, 350px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;    
    box-shadow: 0 0 10px #002147; /* #002147 */
    width: 90%;
    padding:0;
}

.grid-title-inner{
    background: linear-gradient(10deg, #10274d 30%, #115bd1 100%);
    box-shadow: 0 0 10px #002147; /* #002147 */
    width: 100%;
    padding:10;
    margin-bottom:10px;
}



.grid-segment-price{
    padding: 0px;
    margin: 0px;
}
.grid-segment-price :hover{
    cursor:pointer;
}
.grid-col-price{
    background-color: #0c0b14d7;
    border: #002147 2px solid;
    padding:0;
    margin: 0;
    min-height: 200px;
    height: 100%;
}

.text-price{
    font-size: clamp(16px, 2vw, 17px);
    font-weight: 200;
    color: #e9e9e9;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
    display: flex;
    justify-content: center;
}

.text-headline{
    font-size: clamp(17px, 2vw, 19px);
    font-weight: 500;
    color: #e9e9e9;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:5px;
    margin:0;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .text-headline {
        text-wrap: wrap;
    }
    
}
.row-items-price{
    display: flex;
    justify-content: center;
    height:  100%;
    margin-bottom: 20px;
}

.ioncheckbox-included {
    font-size: clamp(14px, 2vw, 16px);
    font-weight: 200;
    color: #F0F8FF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0;
    margin: 0;
    white-space: normal; 

}

.ioncheckbox-included-mini {
    font-size: clamp(12px, 2vw, 14px);
    font-weight: 200;
    color: #cae6ff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0;
    margin: 0;

}

.ioncheckbox {
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    width: 100%;
    padding: 5px;
}

.ioncheckbox:hover {
    background-color: #313131ba;
}

.sixColPriceSegStyle{
    background-size: cover;
    background-position: center;
    min-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 7px;
    border: #002147 4px solid;
    box-shadow: 5px 5px 5px #002147;
    transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
}

.sixColPriceSegStyle:hover{
    box-shadow: 5px 5px 5px #002147;
    transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
    border:#002147 10px solid;
}

.message-input{
    min-height: 200px;
    height: fit-content;
    border: black 2px solid;
    border-radius: 10px;
}
.icon-more-infos{
    font-size: 20px;
    font-weight: 800;
    color: #021325;
    border: black 2px solid;
    border-radius: 100%;

    padding: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.shoppingCartRow{
    padding:0;
    padding-bottom: 5px;
    margin:0;

    z-index:10;
    position: fixed;
    bottom:0;
    height: clamp(70px, 9vw, 90px);
    width: 100%;
    background: linear-gradient(4.6deg, #3c5d97 0%, #204585 45%, transparent 45.5%),
    linear-gradient(4.7deg, #000000 45.5%, transparent 47.5%)
    ;
    
    border-radius: 0% 0% 0% 0% 100%;
    color:white;

    font-size: clamp(16px, 3vw, 22px);
    font-weight: 100;

    display: flex;
    align-items: end;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-left:#000000 7px solid;
    cursor:pointer;
    letter-spacing: 3px;
}

@media screen and (max-width: 1000px) {
    .shoppingCartRow {
        background: linear-gradient(4.5deg, #3c5d97 0%, #204585 70%, transparent 70.5%),
        linear-gradient(4.5deg, #000000 70.5%, transparent 72.5%)
        ;

    }
}

.p-text{
    font-size: 18px;
}

strong{
    font-weight: 500;

}
.shoppingCartRow:hover{
    transition: letter-speacing 2s ease-in-out;

}

.product-in-shoppingCart{
    border-top: #002147 2px solid;
    border-bottom: #002147 2px solid;

    box-shadow: 0 2px 1px #002147; /* #002147 */
        
    
}

.titleWarenkorb{
    text-transform: uppercase;
    display: flex;
    font-size: 35px;
    padding:0;
    margin:0;
    background-color: black;
    color:white;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: #002147 2px solid;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0 0 10px #002147; /* #002147 */
    
}

.rowWarenkorb{
    min-height: 700px;
    /* background: linear-gradient(10deg, #5a7db4a2 0%, #77a3eea8 50%); */

}
.shoppingCartRightCol{
    padding: 0;
    margin:0;
    background: linear-gradient(10deg, #003180 0%, #174ca8 45%,  #1861df 80.1%);
}

.shoppingCart-results{
    padding: 0;
    margin:0;

    color:white;
    font-size: 16px;
    font-weight: 100;
    padding: 0px 10px 0px 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    word-break: break-all;
    hyphens: auto;
    
}

.divider{
    border-bottom: 1px solid #002147;
    margin: 0;
    padding: 0;
}

.datetimepicker{
    width: 100%;
    border:#8f0000 2px solid;
    
}

.datetimepicker:hover{
    border-bottom: 3px solid rgb(51, 71, 253); /* Unterstreichungseffekt */
}

.grid-segment-title{
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    padding-left: 12px;
    align-items: center;
    height:  1000px;
    max-height: 50px;
    text-wrap: wrap;
}

.segment-title-shoppingcart{
    font-size: clamp(17px, 2vw, 23px);
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
}

.image-segment{
    display: flex;
    justify-content: center;
    /* border-radius: 10px; */
    box-shadow: 0 0 7px #002147; 
    width: 100%;
    

}

.select-hours{
    display: flex;
    justify-content: center;
    align-items: center;
    border:#cacaca 2px solid;
    border-radius: 10px;
    padding: 10px;
    height: 45px;
    width: 50%;
    font-size: 18px;
    color: #002244;
}
.select-hours:hover{
    border-bottom: 3px solid rgb(51, 71, 253);
    cursor: pointer;
    
}
.select-hours:focus{
    border-bottom: 3px solid rgb(51, 71, 253);
    cursor: pointer;
}
.p-stundenzahl{
    color: #002244;
    display: flex;
    justify-content: center;
    font-size:18px;
    padding: 0;
    margin: 0;
}



.select-hours option{
    color: #070570;
    background-color: #ecf0f3;
}

.row-voraussichtlicheTätigkeiten{
    padding: 0;
    margin:0;
    color:white;
    font-size: clamp(17px, 2vw, 25px);
    font-weight: 100;
    padding: 5px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    word-break: break-all;
    hyphens: auto;
    margin-Bottom: 20px;

    
}
.row-voraussichtlicheTätigkeiten li{
    list-style-type: none;
    padding: 5px;
    margin: 5px;
     border: #002147 2px solid;
    border-radius: 10px;
    box-shadow: 0 0 7px #002147; 
    background: linear-gradient(10deg, #003180 0%, #174ca8 45%,  #1861df 80.1%);

    display: flex;
    align-items: center;
    word-break: break-all;
    hyphens: auto;
    cursor: pointer; 
    height: fit-content;
}
.row-voraussichtlicheTätigkeiten li p{
    margin:0;
    padding:0;
    color: white;
    font-size: clamp(14px, 2vw, 16px);
    font-weight: 200;
}


.p-title-vorausTätigkeiten{
    font-size: clamp(18px, 2vw, 20px);
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
    hyphens: auto;

}

.checkbox-Stunden-als-Kontingente{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #002244c0;
    padding:0;
    margin:0;
    height: 20px;
}

.checkbox-Stunden-als-Kontingente:hover{
    cursor: pointer;
    color: black;
    font-weight: 600;
}
.row-select-hour{
    margin:0;
    padding:0;
    height: 90px
}


.row-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: -10px;
    margin-bottom: 30px;
}

.shoppingCart-segment{
    margin: 0px;
    padding: 0px;
    padding-left: 15px;
    color:white;
    font-size: clamp(16px, 2vw, 16px);
    font-weight: 500;
    align-items: center;
    word-break: break-all;
    hyphens: auto;
}


.shoppingCart-hours{
    margin: 0px;
    padding: 0px;
    padding-left: 15px;
    color:white;
    font-size: clamp(16px, 2vw, 16px);
    font-weight: 500;
    align-items: center;
    word-break: break-all;
    hyphens: auto;
}

.shoppingCart-task-botengang{
    margin: 0px;
    padding: 0px;
    padding-left: 15px;
    color:white;
    font-size: 14px;
    font-weight: 300;
    align-items: center;
    word-break: break-all;
    hyphens: auto;
}

.grid-hilfstext{
    padding: 10px 100px;
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    height:  fit-content;
}
@media screen and (max-width: 768px) {
    .grid-hilfstext{
        padding: 10px;
    }
    
}
.grid-hilfstext p{
    font-size: clamp(16px, 2vw, 18px);
    font-weight: 200;
    color: #002147;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
    display: flex;
    justify-content: center;
    text-align: center;
}


.p-contact{
    font-size: clamp(16px, 2vw, 18px);
    font-weight: 500;
    color: #e9e9e9;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
    text-decoration: none;
}

.no-decoration {
    text-decoration: underline;
    color: #002147; /* Optional: Behält die ursprüngliche Textfarbe bei */
  }
  .no-decoration:hover {
    text-decoration: none;
  }


  /* FOOTER */

  .style-footer-portfolio{
    background-color: whitesmoke;    
    box-shadow: 0 4px 40px rgba(75, 75, 75, 0.38);
    margin: 0;
    padding: 0;
    display: flex;

    z-index: 100;

    justify-content: end;
    align-items: center; /* Zentriert den Inhalt vertikal */
    height: 115px;
    width:100%;
    color:black;
  }

  .style-footer-portfolio .link{
    padding-right: 50px;
    text-decoration: none;
    color: black;
    font-size: clamp(16px, 2vw, 18px);
    font-weight: 200;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 5px;
  }
  .style-footer-portfolio .link:hover{
    color: rgb(0, 26, 255); /* Textfarbe blau */
    background-color: transparent;
    text-shadow: 0px 5px 20px rgb(0, 26, 255); /* Blaues Leuchten mit Streuung */
    font-weight: 500;
  }

  .divider-kontaktdaten{
    color:white;
    font-size: clamp(17px, 2vw, 25px);
    font-weight: 100;
    padding: 10px;
    text-transform: uppercase;

  }

  ion-item-divider {
    --background: #dadada;
    min-height: 0.1px;
    width: 100%;
    --padding: 0px;
  }

/* -------------------------- PORTFOLIO START -------------------------- */
  .row-products{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    gap: 35px;
  }

  .card-image{
    width: 100%;
  }

  ion-checkbox {
    --size: 20px;
    --checkbox-background-checked: #1567ec;
    width: 100%;
  }
  
  ion-checkbox::part(container) {
    border-radius: 6px;
    border: 2px solid #1567ec;
  }

  .label-text-wrapper {
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    color: #002147; 
}

.p-botengaenge-subtasks{
    font-size: 10px;
    font-weight: 500;
    color: #003775;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0px;
    margin:0px;
}


.native-textarea.sc-ion-textarea-md {
    padding: 3px 5px 5px 5px !important;
}


.modal-img{
    width: 100%;
    height: auto;
    border-radius: 10%;
    box-shadow:  2px 2px 2px 2px #002147;
}
.custom-datetime {
    width: 300px; /* Breite nach Wunsch anpassen */
    height: auto; /* Höhe anpassen, um die Standardhöhe zu erhalten */

}

.box-subtask{
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    max-width: fit-content;
    border: #000000 2px solid;
    border-radius: 20px;
    padding: 0px;
    margin: 0px;
    box-shadow: 0 0 10px #002147; /* #002147 */
    background: linear-gradient(10deg, #133469 0%, #174ca8 45%,  #296fe7 80.1%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin: 5px;
}

.box-subtask p{
    color: white;
    font-size: clamp(12px, 2vw, 12px);
    font-weight: 200;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 4px;
}

.p-modal{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #002147;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
}

.p-modal-details{
    font-size: 14px;
    font-weight: 200;
    color: #002147;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
}

.modal-textarea{
    font-size: 16px;
    font-weight: 200;
    color: #002147;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:0;
    margin:0;
    border: none;
    border-bottom: 4px solid rgba(109, 122, 241, 0.658);
}
.modal-textarea::placeholder {
    color: #666; /* Schriftfarbe der placeholder */
    font-style: italic;
    font-size: 14px;
}
.modal-textarea:focus{
    border-bottom: 4px solid rgb(51, 71, 253); /* Unterstreichungseffekt */
    color:black;
}
.modal-textarea:hover{
    border-bottom: 4px solid rgb(10, 32, 228); /* Unterstreichungseffekt */
    color:black;
}

ion-datetime {
    --background: #f7f7f7;
    --background-rgb: #0022ff;
  
    border-radius: 16px;
    box-shadow: rgba(var(--ion-color-blue-rgb), 0.3) 0px 10px 15px -3px;
  }


.custom-modal {
    --width: 90vw; 
    --height: 75vh;
    --max-width: 800px; 
    --max-height: 870px;
}

.p-modal-title{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #002147;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding:20px;
    margin:0;
}

