/* DIV VISION */


.vision h2 {
    color:white;
    font-size: 75px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-weight: 100;
    margin-bottom: 0;
}

.vision p {
    background-color: #000000d8;
    /* Hintergrundfarbe für den Text */
    padding: 20px;
    /* Innenabstand für den Text */
    text-align: center;
    /* Zentriert den Text horizontal */
    font-size: clamp(1vw, 3vw, 25px);
    /* Mindestgröße, bevorzugte Größe, maximale Größe */
    color:white;
}


/* Buton VISION und WERWIRSIND */
.rowMenuVS button {
    width: 100%;
    border-color:white;
    border-width: 1px;
    background-color: black;
    color:white;
    font-size: clamp(3vw, 4vw, 2rem); /* Mindestgröße, bevorzugte Größe, maximale Größe */
    padding:10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
}
.rowMenuVS button:hover{
    border-color:rgba(38, 0, 255, 0.555); 
    box-shadow: 0px -5px 10px rgba(0, 4, 255, 0.322), 0px 10px 10px rgba(0, 4, 255, 0.322);

}



/* TeamSection */
h1, h2, h3{
    display:flex;
    color:black;
    font-size: clamp(3vw, 5vw, 32);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    margin-bottom: 0;
    margin-top: 0;
    justify-content: center;
    text-wrap: wrap;


}

h2{
    color:white;
    font-size: 60px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top: 50px;
    text-transform: uppercase;


}
h3{
    color:rgb(228, 243, 255);
    font-size: clamp(20px, 4vw, 2rem);
    font-weight: 100;
    padding: 10px;
    letter-spacing: 3px;
}

.card {
    transition: opacity 0.5s, transform 0.5s;
}

.card.isSelected {
    opacity: 1 !important;
    transform: translateX(-50px) scale(1.2);
}
.bio-container{
    width: 100%;
    padding:10px;
}

.bio-container p {
    color: white;
    font-size: clamp(16px, 2vw, 18px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    margin: 0px;
    
    /* Wichtige Eigenschaften für automatische Umbrüche */
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow-wrap: break-word;
}


.bio-container h3 {
    color: white;
}

.h1-founder-name{
    color: #ffffff;
    font-size: clamp(30px, 5vw, 40px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 0;
    padding-bottom: 50px;
    letter-spacing: 2px;
}
.greybackground{
    padding: 5px;
    background-color: #1a1a1ac2;
    height: clamp(550px, 90vw, 650px);
}
@media screen and (max-width: 950px){
    .greybackground{
        height: 100%;
    }
    
}

.founder-image{
    height: clamp(400px, 90vw, 550px);
    object-fit: 'cover' ;
}

.p-founder-name{
    color: #010752;
    font-size: clamp(18px, 2vw, 20px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 0;
    padding: 10px;
    letter-spacing: 2px;
    font-style: italic; /* kursiv hinzufügen */

}