.section-title{
    color: #000d46;
    font-size: clamp(20px, 2vw, 50);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: justify;
    padding: 10px;
    margin: 0;
    white-space: pre-line;
    text-wrap: wrap;
    text-align: center;
}
.subtilte{
    color: #000d46;
    font-size: clamp(15px, 2vw, 17px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: justify;
    padding: 10px 25px;
    margin: 0;
    white-space: pre-line;
    text-wrap: wrap;
    text-align: center;
}
@media screen and (max-width: 768px){
    .subtilte{
        padding: 0px 5px;
    }
    
}

.interactive-image-schlagwort{
    font-size: clamp(14px, 5vw, 27px);
    font-weight: 400;
    font-family: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif";
    color: #002244; /* Broncos Navy*/
    padding: 0;
    margin: 0; 
}
.interactive-image:hover + .interactive-image-schlagwort {
    color: #007FFF; /* Azure*/
    font-weight: 400;
}
