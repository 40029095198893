.blueCol{
    background: linear-gradient(165deg, #3b537a 0%, #5072A7 100%);
  }

  .doubleblueline-whiteMiddle{
    background: linear-gradient(165deg, #3b537a 0%, #5072A7 20%, transparent 20.1%),linear-gradient(-165deg, #3b537a 0%, #5072A7 20%, transparent 20.1%);
}
.mailTo{
    text-decoration: none;
}
.mailTo:hover{
    text-decoration: underline;
}

.row-contactUs{
    display: flex;
    flex-Direction: column;
    padding: 0px 50px 25px 50px;
    background-color: #3b537aF7;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 9, 56, 0.459);
}
@media screen and (max-width: 700px) {
    .row-contactUs {
        padding: 10px;
        margin: 20px 5px 20px 5px;
    }
    
}

.title-box{
    padding: 25px 100px 50px 100px;
}

@media screen and (max-width: 700px) {
    .title-box {
        padding:0px;
    }
}
