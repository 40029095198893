

.row-interactive-images{
    justify-content: center;
    width: 100%;
}
.grid-interactive-image{
    justify-content: center;
    align-items: center;
}

.section-title{
    color: #000D46;
    font-size: clamp(20px, 5vw, 50px);
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 10px;
    text-align: center;
}


.interactive-image {
    height: 25vh;
    width: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    text-align: center;
    box-shadow: #002244 0px 0px 2px 1px; /* Broncos Navy*/

  }

  @media screen and (max-width: 600px){
    .interactive-image {
        height: clamp( 80px, 30vw , 270px);
        width: auto;
        background-size: auto 100%;
        background-position: center;
        display: flex;
        justify-content: center;
        text-align: center;
        box-shadow: #002244 0px 0px 2px 1px; /* Broncos Navy*/
    
      }
  }
  
  .interactive-image-overlay {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5); /* Grauer transparenter Hintergrund */
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    transition: background-color 0.5s; /* Übergangseffekt für die Hintergrundfarbe */
    display: flex;
    justify-content: center;
    align-items: center;
}
  

  .interactive-image-text {
    opacity: 1; /* Standardmäßig sichtbar */
    transition: opacity 0.5s; /* Übergangseffekt für die Transparenz */
    color: white;
    font-size: clamp(12px, 3vw, 30px);
    font-weight: 500;
    margin: 0; 
  }
  
  .interactive-image-overlay:hover .interactive-image-text {
    opacity: 0; /* Beim Überfahren ausblenden */

  }

  
.interactive-image:hover {
    cursor: pointer;
    box-shadow: #002244 0px 0px 10px 5px; /* Broncos Navy*/
}

  .interactive-image-overlay:hover {
    background-color: rgba(0, 0, 0, 0); /* Transparenter Hintergrund */
}


/* Bakcgrounds */
/* Backgrounds */
.background-DeepInfosKrameda{

    height: 100%;
    min-height: 900px;
    padding-top: 50px;
    padding-bottom: 200px;
    background: 
    linear-gradient(190deg, #3b537a 0%, #5072A7 10%, transparent 10.1%),linear-gradient(170deg, #3b537a 0%, #5072A7 10%, transparent 10.1%),
    linear-gradient(10deg, #3b537a 0%, #5072A7 15%, transparent 15.1%),linear-gradient(-10deg, #3b537a 0%, #5072A7 15%, transparent 15.1%);

}
