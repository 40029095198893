.checkout-card{
    box-shadow: 0px 0px 10px 0px #002147;
    border-radius: 10px;
}

.checkout-card-img{
    /* width: clamp(350px, 100%, 470px); */
    width: 100%;
    height: auto;

    
}
.checkout-card-content{
    padding: 10px;
    height: 100%;
}
.checkout-card-title{
    font-size: clamp(22px, 4vw, 32px);
    padding: 0px;
    color: #002147 !important;

}
.checkout-card-subtitle{
    color: #002147 !important;

    font-size: clamp(16px, 1.8vw, 18px);
}
.checkout-card-price{
    color: #002147;
    font-size: clamp(16px, 1.8vw, 19px);
    text-wrap: wrap;
    word-break: break-all;
    word-wrap: break-word;
}
.checkout-card-labels{
    color: #002147;
    font-size: clamp(15px, 1.8vw, 18px);
    font-weight: 500;
    padding: 10px 0px;
}
.checkout-card-labels strong{
    font-weight: 1000;
}
.col-price{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-wrap: wrap;
    word-break: break-all;
    word-wrap: break-word;
}

