

.bg-OurVision{
    height: fit-content;
    background: linear-gradient(10deg, #3b537a 0%, #5072A7 15%, transparent 15.2%), linear-gradient(-10deg, #3b537a 0%, #5072A7 15%, transparent 15.2%);
    margin:0;
    border-bottom: 1px solid #1E2952;
}
@media screen and (max-width: 1200px){
    .bg-OurVision{
        padding: 15px  15px 100px 15px ; 
    }
    
}
.row-grid-vision{
    width: 85%;
    max-width: 1250px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0;
}

.visionPic {
    width: auto;
    height: auto;
    object-fit: cover;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0 20px 10px rgba(0, 0, 0, 0.5)); /* Ändern Sie den Schatten nach Bedarf */

}

.visionPic2 {
    width: 60%;
    height: auto;
    object-fit: cover;
    position: relative;
    margin-right: -250px; /* Ändern Sie diesen Wert entsprechend Ihrer Anforderung */
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5)); /* Ändern Sie den Schatten nach Bedarf */

}
.splitted-phrase{
    color: #000d46;
    font-size: clamp(15px, 2vw, 17px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
    font-weight: 200;
    letter-spacing: 2px;
    font-style: italic;
    margin: 0;
}

.span-phrase{
    min-width: 200px;
}

.OurVision-p-vision{
    color: #000d46;
    font-size: clamp(15px, 2vw, 17px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0;
}
@media screen and (max-width: 1200px){
    .OurVision-p-vision{
        padding-left: 15px;
        padding-right: 15px;
    }
    
}

.row-grid-vision{
    background: #8f8f8f34;
    width: 100%;
}

.OurVision-label-vision{
    color: #00051d;
    font-size: clamp(15px, 2vw, 17px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0;
}

.img-olderPeople{
    width: 100%;
    height: auto;
    object-fit: contain;
    display: flex;
    justify-content: center;
    padding: 100px;
    filter: drop-shadow(0 20px 10px rgba(0, 0, 0, 0.5)); /* Ändern Sie den Schatten nach Bedarf */
}


.p-vision {
    color: #000d46;
    font-size: clamp(15px, 2vw, 17px);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: justify;
    padding: 10px 25px;
    margin: 0;
    white-space: pre-line;
    text-wrap: wrap;
    text-align: center;
}

.col-p-vision{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}


/* .image-container {
    position: relative;
    width: 100%;
    height: 200px;
}

.img-overlap {
    position: absolute;
    width: 70%;
    height: auto;
}

.first-image {
    z-index: 1;
    top: 0;
    left: 20px;
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5)); 
    border-radius: 20%;
}

.second-image {
    z-index: 2;
    top: 250px;  /* Adjust the value to control the amount of overlap */
    /* left: 100px; Adjust the value to control the amount of overlap */
    /* filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.5));  */
/* } */

.double-img{
    width: 100%;
    height: auto;
}
